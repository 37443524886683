import { render, staticRenderFns } from "./AppNavigator.vue?vue&type=template&id=0357d1d2&scoped=true&"
import script from "./AppNavigator.vue?vue&type=script&lang=ts&"
export * from "./AppNavigator.vue?vue&type=script&lang=ts&"
import style0 from "./AppNavigator.vue?vue&type=style&index=0&id=0357d1d2&scoped=true&lang=css&"
import style1 from "./AppNavigator.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0357d1d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VDivider,VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer})
