


















































import { Component, Vue, Prop } from 'vue-property-decorator';
import MixinLogin from '@/mixing/MixinLogin.vue';
import Util from '@/utils/Util';
import { Icon } from '@/model/util/Icon';
import { internet } from '@/utils/Internet';
import { IToken } from '@/model/login/IToken';
import { LOCALES, Locales } from "@/i18n/locales";
import { defaultLocale } from "@/i18n";
@Component({
	name: 'AppBar',
})
export default class AppBar extends MixinLogin {
	public name = '';
	public client = '';
	public exit = this.$t("appBar.exit");
	public interval: any = undefined;
	public locales_array = LOCALES;
	public locale_default = defaultLocale;
	@Prop() readonly keycloak!: string

	mounted() {
		if (this.getUser === undefined) {
			this.$router.push({
				name: 'Login',
			});
		} else {
			this.name = this.getUser.name;
			this.client = this.getUser.groups[0];
			this.interval = setInterval(() => {
				const data = `client_id=${process.env.VUE_APP_KEYCLOAK_CLIENT}&grant_type=refresh_token&refresh_token=${this.getToken.refresh_token}`;
				internet
					.newRequest()
					.post(
						`${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
						data,
						{
							headers: {
								'Content-Type':
									'application/x-www-form-urlencoded',
							},
						}
					)
					.then((respuesta) => {
						this.refreshToken(
							respuesta.data as IToken
						);
					});
			}, (this.getToken.expires_in - 30) * 1000);
			

		}
	}
	public destroyed(): void {
		clearInterval(this.interval);
	}
	public listenLogout() {
		//this.logout();
		//Util.showMessage('Hasta pronto', Icon.INFO);
		this.$router.push({
			name: 'Login',
		});
		//this.keycloak.logout();

	}
	public updateLanguage(lang: Locales) {
		console.log("cambiando idioma a ");
		this.$emit('changeEvent', lang);
	}
	// public showMenu() {
	// 	this.$root.$refs.compmenu_component.showMenu2();
	// }	
}
