



















import { Component, Vue, Prop} from 'vue-property-decorator';
import AppNavigator from '@/components/util/AppNavigator.vue';
import AppBar from '@/components/util/AppBar.vue';
import AppFooter from '@/components/util/AppFooter.vue';
@Component({
	name: 'MainBase',
	components: {
		AppNavigator,
		AppBar,
		AppFooter,
	},
})


export default class MainBase extends Vue {
	public drawer = false;
	public reRenderVar = 0;

	@Prop() readonly keycloak!: string

	mounted() {
		//console.log("keycloak1", this.keycloak)
	}

	public reRender() {
		console.log("Recibido en MAIN BASE");
		this.reRenderVar += 1;
		this.$emit('changeEvent');
	}
}

