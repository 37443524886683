










import { Component, Vue } from 'vue-property-decorator';
import { MainBase } from '@/config/Text.json';
@Component({
	name: 'AppFooter',
})
export default class AppFooter extends Vue {
	protected messageFooter: string = MainBase.textFooter;
}
